// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._2CSmmmk9jBkSueMEPbcLvh {\n  color: #F9FD50;\n  background-color: #00BD56;\n  width: 80vw;\n  max-width: 875px;\n  padding-right: 2em;\n  min-height: 70vh;\n\n  text-align: center;\n}\n\n  ._2CSmmmk9jBkSueMEPbcLvh img {\n    width: 280px;\n    border-radius: 8px;\n    margin: 0.4em;\n    display: inline-block;\n  }\n\n._3KHzugKXdMgQbCRGV7WdOm {\n  height: 1.2em;\n}\n\n.R3s8gFQhHo_mNFC6hTgTJ {\n  display: flex-wrap;\n  justify-content: center;\n  margin-bottom: 1.8em;\n  \n}\n\n._2plE79k4VBoOuO3tnuZT3- {\n  display: inline-block;\n  position: relative;\n  vertical-align: middle;\n}\n\n._3NbjA6lGnANkkHHYzmyjxj {\n  padding: 12px;\n  display: inline-block;\n  max-width: 500px;\n  vertical-align: middle\n}\n\n._1V9mP9mtMh_AXSW4YF6SqI {\n  margin: 1em;\n}\n\n\n\n", "",{"version":3,"sources":["projects.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,yBAAyB;EACzB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;;EAEhB,kBAAkB;AAQpB;;EANE;IACE,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,qBAAqB;EACvB;;AAGF;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,oBAAoB;;AAEtB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,gBAAgB;EAChB;AACF;;AAEA;EACE,WAAW;AACb","file":"projects.css","sourcesContent":[".projectsComp {\n  color: #F9FD50;\n  background-color: #00BD56;\n  width: 80vw;\n  max-width: 875px;\n  padding-right: 2em;\n  min-height: 70vh;\n\n  text-align: center;\n\n  img {\n    width: 280px;\n    border-radius: 8px;\n    margin: 0.4em;\n    display: inline-block;\n  }\n}\n\n.spacerDiv {\n  height: 1.2em;\n}\n\n.projBlock {\n  display: flex-wrap;\n  justify-content: center;\n  margin-bottom: 1.8em;\n  \n}\n\n.projImg {\n  display: inline-block;\n  position: relative;\n  vertical-align: middle;\n}\n\n.projText {\n  padding: 12px;\n  display: inline-block;\n  max-width: 500px;\n  vertical-align: middle\n}\n\n.ghLink {\n  margin: 1em;\n}\n\n\n\n"]}]);
// Exports
exports.locals = {
	"projectsComp": "_2CSmmmk9jBkSueMEPbcLvh",
	"spacerDiv": "_3KHzugKXdMgQbCRGV7WdOm",
	"projBlock": "R3s8gFQhHo_mNFC6hTgTJ",
	"projImg": "_2plE79k4VBoOuO3tnuZT3-",
	"projText": "_3NbjA6lGnANkkHHYzmyjxj",
	"ghLink": "_1V9mP9mtMh_AXSW4YF6SqI"
};
module.exports = exports;
