// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/bgtest1.png");
exports = ___CSS_LOADER_API_IMPORT___(true);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Nunito&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Exo+2:800&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "body {\n  font-family: 'Nunito', sans-serif;\n  background-color: rgb(0, 51, 23);\n  background-color: #551A8B;\n  /* background-image: linear-gradient(to bottom, #9640e7, rgb(99, 33, 161), #551A8B, #3d1364); */\n  /* background-image: linear-gradient(to bottom, #a74afd, rgb(99, 33, 161), #551A8B, #300f50); */\n  /* background-image: linear-gradient(to bottom, #b86bff 1%, rgb(99, 33, 161), #551A8B 60%, #1c092f);\n  // background-repeat: no-repeat;\n  // background-attachment: fixed; */\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  color: #85EF47;\n  display: flex;\n  justify-content: center;\n  padding: 0;\n  margin-left: 0;\n  margin-right: 2em;\n}\n", "",{"version":3,"sources":["index.css"],"names":[],"mappings":"AAGA;EACE,iCAAiC;EACjC,gCAAgC;EAChC,yBAAyB;EACzB,+FAA+F;EAC/F,+FAA+F;EAC/F;;oCAEkC;EAClC,yDAA6C;EAC7C,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,UAAU;EACV,cAAc;EACd,iBAAiB;AACnB","file":"index.css","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Nunito&display=swap');\n@import url('https://fonts.googleapis.com/css?family=Exo+2:800&display=swap');\n\nbody {\n  font-family: 'Nunito', sans-serif;\n  background-color: rgb(0, 51, 23);\n  background-color: #551A8B;\n  /* background-image: linear-gradient(to bottom, #9640e7, rgb(99, 33, 161), #551A8B, #3d1364); */\n  /* background-image: linear-gradient(to bottom, #a74afd, rgb(99, 33, 161), #551A8B, #300f50); */\n  /* background-image: linear-gradient(to bottom, #b86bff 1%, rgb(99, 33, 161), #551A8B 60%, #1c092f);\n  // background-repeat: no-repeat;\n  // background-attachment: fixed; */\n  background-image: url(\"./assets/bgtest1.png\");\n  color: #85EF47;\n  display: flex;\n  justify-content: center;\n  padding: 0;\n  margin-left: 0;\n  margin-right: 2em;\n}\n"]}]);
// Exports
module.exports = exports;
