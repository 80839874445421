// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._1wTODSNw08XpRskFIpk5C9 {\n  z-index: 1000;\n  color: #85EF47;\n  color: #F9FD50;\n  background-color: #00BD56;\n  width: 80vw;\n  max-width: 875px;\n  /*min-height: 70vh;*/\n  padding-right: 2em;\n  opacity: 1;\n}\n  ._1wTODSNw08XpRskFIpk5C9 img {\n    height: 58vh;\n    float: right;\n  }\n  ._1wTODSNw08XpRskFIpk5C9 ._1FV23PdlaVko2HMpcT02id {\n    padding: 1em;\n    padding-left: 2em;\n    font-size: 1.2em;\n  }", "",{"version":3,"sources":["home.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,cAAc;EACd,yBAAyB;EACzB,WAAW;EACX,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB;EAClB,UAAU;AAUZ;EATE;IACE,YAAY;IACZ,YAAY;EACd;EACA;IACE,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;EAClB","file":"home.css","sourcesContent":[".home {\n  z-index: 1000;\n  color: #85EF47;\n  color: #F9FD50;\n  background-color: #00BD56;\n  width: 80vw;\n  max-width: 875px;\n  /*min-height: 70vh;*/\n  padding-right: 2em;\n  opacity: 1;\n  img {\n    height: 58vh;\n    float: right;\n  }\n  .textPara {\n    padding: 1em;\n    padding-left: 2em;\n    font-size: 1.2em;\n  }\n}"]}]);
// Exports
exports.locals = {
	"home": "_1wTODSNw08XpRskFIpk5C9",
	"textPara": "_1FV23PdlaVko2HMpcT02id"
};
module.exports = exports;
