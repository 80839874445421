// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Exo+2:800&display=swap);"]);
// Module
exports.push([module.id, ".FzkgfiHuSrkyneJ6N35S1 {\n  background-color: #00BD56;\n  border-top-right-radius: 2em;\n  display: block;\n}\n\n._2m9dqKWO5gZmmOzue7sI9G {\n  font-family: 'Nunito', sans-serif;\n  background-color: #F9FD50;\n  color: #00BD56;\n  width: 80vw;\n  max-width: 875px;\n  padding: 1em;\n  border-bottom-left-radius: 2em;\n  border-top-right-radius: 2em;\n}\n\n._2m9dqKWO5gZmmOzue7sI9G a {\n    text-decoration: none;\n    color: #00BD56;\n  }\n\n._1u86wzsiVAjS1OJ_xmYkEi {\n  font-family: 'Exo 2', sans-serif;\n}", "",{"version":3,"sources":["header.css"],"names":[],"mappings":"AAEA;EACE,yBAAyB;EACzB,4BAA4B;EAC5B,cAAc;AAChB;;AAEA;EACE,iCAAiC;EACjC,yBAAyB;EACzB,cAAc;EACd,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,8BAA8B;EAC9B,4BAA4B;AAK9B;;AAJE;IACE,qBAAqB;IACrB,cAAc;EAChB;;AAGF;EACE,gCAAgC;AAClC","file":"header.css","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Exo+2:800&display=swap');\n\n.wayback {\n  background-color: #00BD56;\n  border-top-right-radius: 2em;\n  display: block;\n}\n\n.header {\n  font-family: 'Nunito', sans-serif;\n  background-color: #F9FD50;\n  color: #00BD56;\n  width: 80vw;\n  max-width: 875px;\n  padding: 1em;\n  border-bottom-left-radius: 2em;\n  border-top-right-radius: 2em;\n  a {\n    text-decoration: none;\n    color: #00BD56;\n  }\n}\n\n.difFont {\n  font-family: 'Exo 2', sans-serif;\n}"]}]);
// Exports
exports.locals = {
	"wayback": "FzkgfiHuSrkyneJ6N35S1",
	"header": "_2m9dqKWO5gZmmOzue7sI9G",
	"difFont": "_1u86wzsiVAjS1OJ_xmYkEi"
};
module.exports = exports;
