// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".G_tSfgp_AclI7T0V7i4G0 {\n  background-color: #207DFF;\n  color: #00BD56;\n  color: #F9FD50;\n  color: #85EF47;\n  font-size: 1.1em;\n  position: relative;\n  margin-bottom: 0;\n  bottom: 0;\n  width: 80vw;\n  max-width: 875px;\n  padding: 1.2em;\n  display: inline-block;\n  padding-right: 0.84em;\n  padding-left: 1em;\n}\n\n.G_tSfgp_AclI7T0V7i4G0 div {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 2px;\n}", "",{"version":3,"sources":["footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,cAAc;EACd,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,cAAc;EACd,qBAAqB;EACrB,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;AACpB","file":"footer.css","sourcesContent":[".footer {\n  background-color: #207DFF;\n  color: #00BD56;\n  color: #F9FD50;\n  color: #85EF47;\n  font-size: 1.1em;\n  position: relative;\n  margin-bottom: 0;\n  bottom: 0;\n  width: 80vw;\n  max-width: 875px;\n  padding: 1.2em;\n  display: inline-block;\n  padding-right: 0.84em;\n  padding-left: 1em;\n}\n\n.footer div {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 2px;\n}"]}]);
// Exports
exports.locals = {
	"footer": "G_tSfgp_AclI7T0V7i4G0"
};
module.exports = exports;
