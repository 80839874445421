// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._1G8d9P20Us0yBsc5ISq8hr {\n  z-index: 1000;\n  color: #85EF47;\n  color: #F9FD50;\n  background-color: #00BD56;\n  border-top-right-radius: 2em;\n  width: 80vw;\n  max-width: 875px;\n  opacity: 1;\n}", "",{"version":3,"sources":["mainbody.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,cAAc;EACd,yBAAyB;EACzB,4BAA4B;EAC5B,WAAW;EACX,gBAAgB;EAChB,UAAU;AACZ","file":"mainbody.css","sourcesContent":[".mainbody {\n  z-index: 1000;\n  color: #85EF47;\n  color: #F9FD50;\n  background-color: #00BD56;\n  border-top-right-radius: 2em;\n  width: 80vw;\n  max-width: 875px;\n  opacity: 1;\n}"]}]);
// Exports
exports.locals = {
	"mainbody": "_1G8d9P20Us0yBsc5ISq8hr"
};
module.exports = exports;
