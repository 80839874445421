// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._2zDhCfJgKs2SiqyF0G8Wvi {\n  display: flex;\n  flex-wrap: wrap;\n  background-color: #00BD56;\n  font-size: 1.6em;\n  padding-right: 1.26em;\n  width: 875px;\n  max-width: 80vw;\n}\n\n._3JNMesdbn5oKTkN6llmwrO {\n  margin-right: 3vw;\n  text-decoration: none;\n}\n\n.Zcgu30aleQh9bSYhgwtLG {\n  margin: 1em;\n  display: flex;\n  flex-wrap: wrap;\n}", "",{"version":3,"sources":["navbar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,yBAAyB;EACzB,gBAAgB;EAChB,qBAAqB;EACrB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,eAAe;AACjB","file":"navbar.css","sourcesContent":[".navBar {\n  display: flex;\n  flex-wrap: wrap;\n  background-color: #00BD56;\n  font-size: 1.6em;\n  padding-right: 1.26em;\n  width: 875px;\n  max-width: 80vw;\n}\n\n.link {\n  margin-right: 3vw;\n  text-decoration: none;\n}\n\n.linkContainer {\n  margin: 1em;\n  display: flex;\n  flex-wrap: wrap;\n}"]}]);
// Exports
exports.locals = {
	"navBar": "_2zDhCfJgKs2SiqyF0G8Wvi",
	"link": "_3JNMesdbn5oKTkN6llmwrO",
	"linkContainer": "Zcgu30aleQh9bSYhgwtLG"
};
module.exports = exports;
